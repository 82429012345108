<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <b-container>
          <b-row>
            <div>
              <b-tabs justified>
                <b-tab>
                  <template #title>
                    <span class="small fw-bold">CONTROLS</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Daily Products Customers</p>
                          <p class="mb-0">
                            This report shows customers with savings products <br />created within a certain period
                          </p>
                          <a href="/reports/products/customers" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Daily Products Customers (by RO)</p>
                          <p class="mb-0">
                            This report shows customers with savings products <br />created within a certain period by
                            RO
                          </p>
                          <a href="/reports/products/customers/ro" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Customers Controls Balance Listing</p>
                          <p class="mb-0">This report show consolidated product balance
                            <br />(shares,savings,current,fixed deposit, (all
                            product) <br /> and loans) for members</p>
                          <a href="/reports/customer/control/balance" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Product Balance TERMS</p>
                          <p class="mb-0">This report show various product balances for<br /> members per specific
                            amount.</p>
                          <a href="/reports/product/balance/term" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Member Statistic</p>
                          <p class="mb-0">
                            This shows list of product and balances, group by<br />
                            membership type and gender
                          </p>
                          <a href="/reports/member/statistic" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">RO Performance Analysis : General</p>
                          <p class="mb-0">This report shows sales performance report for relationship<br /> officers,
                            Teller and Mobile
                            Banker</p>
                          <a href="/reports/performance/analysis" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">RO Performance Analysis : RO Specific</p>
                          <p class="mb-0">This report shows sales performance report for relationship<br /> officers,
                            Teller and Mobile
                            Banker</p>
                          <a href="/reports/performance/analysis" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">User Coa Transaction Tracker</p>
                          <p class="mb-0">This report shows user coa tracker</p>
                          <a href="/reports/controls/user-coa-tracker" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Consolidated RO’s Performance Report</p>
                          <p class="mb-0">This report shows RO´s Total Mobilization for a period</p>
                          <a href="/reports/controls/consolidated/performance" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Detailed RO’s performance report</p>
                          <p class="mb-0">This report shows RO´s Detail mobilization for a period</p>
                          <a href="/reports/controls/single/consolidated/performance" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Detailed RO’s commission report</p>
                          <p class="mb-0">This report shows RO´s Detail mobilization for a period</p>
                          <a href="/reports/controls/single/consolidated/commission" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <span class="small fw-bold">FINANCIALS</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Trial Balance</p>
                          <p class="mb-0">
                            A trial balance is a financial report of credit<br />
                            entries and debit entries that businesses use to<br />
                            internally audit their double-entry accounting systems
                          </p>

                          <a href="/reports/trial-balance" class="text-decoration-none akkurate-green">
                            View Report
                          </a>
                        </div>
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Chart of Account</p>
                          <p class="mb-0">
                            A report that shows flow of funds for chart of account
                          </p>
                          <a href="/reports/coa" class="text-decoration-none akkurate-green">
                            View Report
                          </a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">COA Heads Summary</p>
                          <p class="mb-0">
                            A report that shows COA heads summary
                          </p>
                          <a href="/reports/financials/coa/heads/summary" class="text-decoration-none akkurate-green">
                            View Report
                          </a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Single Chart of Account</p>
                          <p class="mb-0">
                            A report that shows flow of funds for a single chart of account
                          </p>
                          <a href="/reports/financials/single-coa" class="text-decoration-none akkurate-green">
                            View Report
                          </a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Receipts & Payments Summary</p>
                          <p class="mb-0">
                            A report that shows receipts and payments summary details
                          </p>
                          <a href="/reports/receipt-payment/summary"
                            class="text-decoration-none akkurate-green">View Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Note to Receipts & Payments</p>
                          <p class="mb-0">
                            A report that shows a detailed receipts and payments details
                          </p>
                          <a href="/reports/receipt-payment/detailed"
                            class="text-decoration-none akkurate-green">View Report</a>
                        </div>


                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Income and Expenses Statement Summary</p>
                          <p class="mb-0">
                            This shows the details into whether a company is making profit or loss for a given period.
                          </p>
                          <a href="/reports/financials/income-statement/summary"
                            class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Note to the Income and Expenses Statement</p>
                          <p class="mb-0">
                            This shows whether a company is making profit or loss for a given period.
                          </p>
                          <a href="/reports/financials/income-statement"
                            class="text-decoration-none akkurate-green">View Report</a>
                        </div>


                       


                     
                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Balance Sheet Summary</p>
                          <p class="mb-0">
                            A report that shows summary of the finanacial position for a given period
                          </p>
                          <a href="/reports/financials/balance-sheet/summary"
                            class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>

                          

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Note to the Balance Sheet</p>
                          <p class="mb-0">
                            A report that shows the finanacial position for a given period
                          </p>
                          <a href="/reports/financials/balance-sheet" class="text-decoration-none akkurate-green">View
                            Report</a>
                        </div>


                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Receipts & Payments</p>
                          <p class="mb-0">
                            A report that shows receipts and payments details
                          </p>
                          <a href="/reports/financials/receipts-payments"
                            class="text-decoration-none akkurate-green">View Report</a>
                        </div>

                       
                       

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Note to Members Payments Report</p>
                          <p class="mb-0">
                            A report that shows detailed payments of members
                          </p>
                          <a href="/reports/financials/payments/members"
                            class="text-decoration-none akkurate-green">View Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Note to Members Receipts Report</p>
                          <p class="mb-0">
                            A report that shows detailed receipts of members
                          </p>
                          <a href="/reports/financials/receipts/members"
                            class="text-decoration-none akkurate-green">View Report</a>
                        </div>

                       

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Journal report</p>
                          <p class="mb-0">
                            A report that shows journal details and breakdown
                          </p>
                          <a href="/reports/journal" class="text-decoration-none akkurate-green">View Report</a>
                        </div>

                        <div class="col-md-6 my-3">
                          <p class="mb-0 fw-bold">Voucher report</p>
                          <p class="mb-0">
                            A report that shows voucher details and breakdown
                          </p>
                          <a href="/reports/voucher" class="text-decoration-none akkurate-green">View Report</a>
                        </div>

                       
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <span class="small fw-bold">LOANS</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <div class="my-3 ms-3">
                        <p class="mb-0 fw-bold">Aging Report</p>
                        <p class="mb-0">
                          A report that shows how long loan has been in default
                        </p>
                        <a href="/reports/aging" class="text-decoration-none akkurate-green">View Report</a>
                      </div>


                      <div class="my-3 ms-3">
                        <p class="mb-0 fw-bold">Expected Loan Repayment Report</p>
                        <p class="mb-0">
                          A report that shows the expected loan repayments for a period of time
                        </p>
                        <a href="/reports/loans/expected-repayments" class="text-decoration-none akkurate-green">View
                          Report</a>
                      </div>

                      <div class="my-3 ms-3">
                        <p class="mb-0 fw-bold">Loan Repayment Report</p>
                        <p class="mb-0">
                          A report that shows loan paid within a period
                        </p>
                        <a href="/reports/loan/repayment" class="text-decoration-none akkurate-green">View Report</a>
                      </div>

                      <div class="my-3 ms-3">
                        <p class="mb-0 fw-bold">Loan Disbursement Report</p>
                        <p class="mb-0">
                          A report that shows loan paidout to customer within a period
                        </p>
                        <a href="/reports/loan/disbursed" class="text-decoration-none akkurate-green">View Report</a>
                      </div>

                      <div class="my-3 ms-3">
                        <p class="mb-0 fw-bold">Delinquency Report</p>
                        <p class="mb-0">
                          A report that shows how long loan has been in default
                        </p>
                        <a href="/reports/delinquency" class="text-decoration-none akkurate-green">View Report</a>
                      </div>

                      <div class="my-3 ms-3">
                        <p class="mb-0 fw-bold">Open/Expired Report</p>
                        <p class="mb-0">
                          A report that shows loan which could be paid off within the agreed tenure and their maturity
                          data has elaspsed
                        </p>
                        <a href="/reports/open/close" class="text-decoration-none akkurate-green">View Report</a>
                      </div>

                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <span class="small fw-bold">STATISTICS</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <div class="my-3 ms-3">
                            <p class="mb-0 fw-bold">Account Statistic Detailed Report</p>
                            <p class="mb-0">
                              A report that shows detailed account statistics
                            </p>
                            <a href="/reports/account/statistics" class="text-decoration-none akkurate-green">View
                              Report</a>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="my-3 ms-3">
                            <p class="mb-0 fw-bold">Account Statistic Report</p>
                            <p class="mb-0">
                              A report that shows account statistics
                            </p>
                            <a href="/reports/account/brief/statistics" class="text-decoration-none akkurate-green">View
                              Report</a>
                          </div>
                        </div>
                      </div>




                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <span class="small fw-bold">ORASS</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <!-- <comment></comment> -->
                      <div class="text-end py-3">
                        <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-2">
                          <font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <span class="small fw-bold">BUDGETARY</span>
                  </template>
                  <div class="card">
                    <div class="card-body">
                      <!-- <history></history> -->
                      <div class="text-end py-3">
                        <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-2">
                          <font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-row>
        </b-container>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
  },
};
</script>