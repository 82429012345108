<template>
    <div id="wrapper">
        <SidebarView :memacc="true" :importMembers="true" :memaccVisible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />

                <div class="mx-3">
                    <div>
                        <b-card no-body class="card-shape home-box">
                            <b-tabs card>
                                <b-tab title="Account Imports" active>
                                    <AccountImports />
                                </b-tab>
                                <b-tab title="Commission Imports">
                                    <CommissionImports />
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import AccountImports from "./AccountImports.vue";
import CommissionImports from "./CommissionImports.vue";

export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        AccountImports,
        CommissionImports,
    },
};
</script>