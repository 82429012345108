<template>
    <div id="wrapper">
        <SidebarView :reports="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container">
                    <div class="card card-shape home-box">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h6 class="m-0 fw-bold text-green">
                                        Detailed RO's Commission Report
                                    </h6>
                                </div>
                                <div class="col-6 text-end">
                                    <b-button @click="generateExcel"
                                        class="btn-sm ms-5 me-2 akkurate-green-btn akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'file-excel']" />
                                    </b-button>
                                    <report-filter :form="form" @filterAction="filterAction"
                                        :filter="filter"></report-filter>
                                    <b-button @click="printPDF" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'book']" />
                                    </b-button>
                                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'download']" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="row" ref="pdfContent">
                                <div class="col-12 col-md-12">
                                    <statement-institution :institution="institution"
                                        :title="'DETAILED RO`S COMMISSION REPORT'"></statement-institution>
                                </div>
                                <hr />
                                <div class="row mb-2">
                                    <div class="col-12 small">
                                        Branch(es) : {{ branch_list }}
                                    </div>
                                    <div class="col-12 small">
                                        User(s) : {{ user_list }}
                                    </div>
                                    <div class="col-12 small">
                                        Product(s) : {{ product_list }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-end">
                                        <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                                        <span class="mt-2">From: <strong>{{ form.start_date }}</strong> To: <strong>{{
                                            form.end_date }}</strong> </span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 trans_details">
                                    <b-table striped bordered responsive id="global-performance-table"
                                        :busy.sync="isBusy" :items="items" :fields="fields" :tbody-tr-class="rowClass"
                                        show-empty>
                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>
                                        <template #cell(total_commission)="data">
                                            <span>{{ institution?.currency_code + ' ' +
                                                displayNumber(data.item.total_commission) }}</span>
                                        </template>
                                        <template #custom-foot>
                                            <b-tr class="fw-bold">
                                                <b-td colspan="3">Total</b-td>
                                                <b-td colspan="2">{{ institution?.currency_code + ' ' +
                                                    displayNumber(totalReceipt) }}</b-td>
                                            </b-tr>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage"
                                    :total-rows="totalRecords" :per-page="perPage"
                                    aria-controls="member-table"></b-pagination>
                                <div class="col text-end mt-1 akkurate-dark">
                                    <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                                    <b-form-group label="Per Page" label-size="sm" class="mb-0">
                                        <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                                            :options="per_page_options" size="sm"></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top">
            <i class="fas fa-angle-up"></i>
        </a>
    </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import ApiService from "@/core/services/api.service";
import html2pdf from "html2pdf.js";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import Multiselect from "vue-multiselect";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';

export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        StatementInstitution,
        Multiselect,
        ReportFilter,
    },
    mounted() {
        this.loadItems();
        this.getInstitution();
        this.getProductDropdown();
    },

    data() {
        return {
            form: {
                start_date: this.getBeginningOfYear(),
                end_date: new Date().toISOString().split('T')[0],
                branches: [],
                products: [],
                branch_options: [],
                product_options: [],
                users: [],
                users_options: [],
                status: [],
                coa: [],
                coa_options: [],
                terms: "Periodic",
                terms_options: ["YTD", "Periodic"],
                categories: [],
            },
            filter: {
                branches: true,
                products: true,
                users: true,
                status: false,
                condition: false,
                terms: true,
                coa: false,
                voucher_status: false,
                start_date: true,
                end_date: true,
            },
            search: "Search",
            isBusy: false,
            isSearching: false,
            per_page_options: ['10', '20', '50', '100'],
            per_page_selected: "10",
            currentPage: 1,
            perPage: 10,
            totalRecords: 0,
            isLoadingInstitution: false,
            institution: "",
            fields: [
                {
                    key: "date",
                    label: "Date",
                },
                {
                    key: "account_name",
                    label: "Account name",
                },
                {
                    key: "account_no",
                    label: "Account Number",
                },
                {
                    key: "total_commission",
                    label: "Total Commission",
                },
            ],
            perPage: 20,
            currentPage: 1,
            items: [],
            branch_list: "",
            user_list: "",
            product_list: "",
        };

    },
    computed: {
        totalReceipt() {
            return this.items.reduce((sum, transaction) => {
                return sum + transaction.total_commission;
            }, 0);
        }
    },
    methods: {
        rowClass() {
            return 'custom-row-height'
        },
        navigatePage(index) {
            this.isBusy = true;
            this.currentPage = index;
            this.loadItems()
        },
        selectPerPage(index) {
            this.perPage = index
            this.loadItems()
        },
        async getProductDropdown() {
            this.isBusy = true;
            this.search = "Processing...";
            await ApiService.query("/reports/controls/products/dropdown")
                .then((response) => {
                    this.isBusy = false;
                    this.search = "Search";
                    this.form.branch_options = response.data.branches;
                    this.form.product_options = response.data.products;
                    this.form.users_options = response.data.users;
                })
                .catch((error) => {
                    console.log(error);
                    this.isBusy = false;
                    this.search = "Search";
                });
        },
        async loadItems() {
            this.isBusy = true;
            this.search = "Processing...";
            this.isSearching = true;
            await ApiService.post("/reports/controls/single/consolidated/commission", {
                "page": this.currentPage,
                "per_page": this.perPage,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                product: this.form.products.map((index) => { return index.id }),
                branch: this.form.branches.map((index) => { return index.id }),
                term: this.form.terms, //['periodic','YTD]
                user_id: this.form.users.map((index) => { return index.id }),
            })
                .then((response) => {
                    this.items = [];
                    this.isBusy = false;
                    this.search = "Search";
                    this.isSearching = false;
                    this.$Progress.start();
                    this.items = response.data.transactions
                    this.totalRecords = response.data.meta.total;
                    this.branch_list = this.form.branches.map(branch => branch.name).join(", ");
                    this.user_list = this.form.users.map(user => user.first_name).join(", ");
                    this.product_list = this.form.products.map(product => product.name).join(", ");
                })
                .catch((error) => {
                    this.isBusy = false;
                    this.isSearching = false;
                    this.search = "Search";
                    console.log(error);
                    this.$Progress.fail();
                })
                .finally(() => {
                    this.$Progress.finish();
                });
        },
        downloadPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
                filename: "statement.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                pagebreak: { mode: "avoid-all" },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(
                            [
                                "For Inquiries, Please Contact " +
                                insit_name +
                                ", Customer Service on " +
                                insit_tele +
                                "",
                                "or email us on " + insit_email + " " + insit_motto + "",
                                "Page " + i + " of " + totalPages,
                            ],
                            pdf.internal.pageSize.getWidth() / 2,
                            pdf.internal.pageSize.getHeight() - 0.6,
                            { align: "center" }
                        );
                    }
                })
                .save();
        },
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
                filename: "statement.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                pagebreak: { mode: "avoid-all" },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(
                            [
                                "For Inquiries, Please Contact " +
                                insit_name +
                                ", Customer Service on " +
                                insit_tele +
                                "",
                                "or email us on " + insit_email + " " + insit_motto + "",
                                "Page " + i + " of " + totalPages,
                            ],
                            pdf.internal.pageSize.getWidth() / 2,
                            pdf.internal.pageSize.getHeight() - 0.6,
                            { align: "center" }
                        );
                    }
                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(
                        pdfUrl,
                        "_blank",
                        "width=800,height=600"
                    );
                    printWindow.onload = () => {
                        printWindow.print();
                        URL.revokeObjectURL(pdfUrl);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },
        filterAction() {
            this.changePerPageNumber(this.perPage);
        },
        changePerPageNumber(data) {
            if (data == "All") {
                this.perPage = this.items.length;
                this.loadItems();
            } else {
                this.perPage = data;
                this.loadItems();
            }
        },
        async getInstitution() {
            this.isLoadingInstitution = true;
            await ApiService.get("/settings/institution")
                .then((response) => {
                    this.institution = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoadingInstitution = false;
                });
        },
        getOneMonthBeforeDate() {
            // Get today's date
            let currentDate = new Date();

            // Remove one month to the current date
            currentDate.setMonth(currentDate.getMonth() - 1);

            // Format the date as 'YYYY-MM-DD'
            return currentDate.toISOString().substr(0, 10);
        },
        async generateExcel() {
            const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
            const imageUrl = '/default_logo.png';  // Path to your image file

            // Institution details
            const institutionName = this.institution.name;
            const institutionMotto = this.institution.motto;
            const institutionAddress = this.institution.address;
            const institutionPhone = this.institution.telephone;
            const institutionEmail = this.institution.email;

            try {
                // Fetch the Excel file from the public URL
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                const arrayBuffer = await response.arrayBuffer();

                // Load the file into ExcelJS
                const wb = new ExcelJS.Workbook();
                await wb.xlsx.load(arrayBuffer);

                // Access the first sheet
                const ws = wb.getWorksheet(1); // Get the first worksheet

                // Add Image to the top of the sheet
                const imageResponse = await fetch(imageUrl);
                const imageBuffer = await imageResponse.arrayBuffer();
                const imageId = wb.addImage({
                    buffer: imageBuffer,
                    extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
                });

                // Insert the image at the top-left corner (row 1, column 1)
                ws.addImage(imageId, {
                    tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
                    ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
                });

                // Insert institution details next to the image (starting at row 1, col 5)
                const institutionDetailsStartCol = 5; // Column where text starts after image
                const institutionDetailsRow = 1;  // Row for institution details

                // Add the institution name, address, and phone number
                ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
                ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

                ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
                ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

                ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
                ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

                ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
                ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

                ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
                ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

                // Optionally adjust the column width if the text is long
                ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

                // Define header row
                const header = [
                    "Acct. Number",
                    "Account Name",
                    "Mobile",
                    "Gender",
                    "Member Saving",
                    "Loan Account",
                    "Member Shares",
                    "Fixed Deposit",
                    "Others",
                ];



                // Add header row starting from row 13 (or the next row after the image and details)
                const startingRow = institutionDetailsRow + 10;  // Row 4 after institution details
                let headerRow = ws.getRow(startingRow);
                headerRow.values = header;
                headerRow.font = { bold: true };
                headerRow.commit(); // Commit the row to the worksheet

                // Add individual data rows
                let currentRow = startingRow + 1;

                // Process each item
                this.items.forEach(item => {
                    const row = ws.getRow(currentRow);
                    // Map item values to the corresponding header columns
                    row.values = [
                        item.account_number,
                        item.customer.trim(), // Remove extra spaces
                        item.mobile,
                        item.gender,
                        item.member_saving,
                        item.loan_account,
                        item.member_shares,
                        item.fixed_deposit,
                        item.others
                    ];
                    row.commit();
                    currentRow++;
                });
                const buffer = await wb.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a download link and simulate a click to download the file
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Modified_REPORT_TEMPLATE.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (err) {
                console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
            }
        }
    },
};
</script>

<!-- <style>
  /* Customize the pagination page number color */
  .pagination {
    --bs-pagination-active-bg: rgb(1, 134, 115) !important;
    --bs-pagination-active-border-color: rgb(1, 134, 115) !important;
    --bs-pagination-focus-box-shadow: none !important;
  }
  
  .page-link {
    color: rgb(1, 134, 115) !important;
  }
  
  .page-link:hover {
    color: rgb(1, 134, 115) !important;
  }
  
  .page-link.active,
  .active>.page-link {
    color: #fff !important;
  }
  </style> -->